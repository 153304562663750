export enum CallbackType {
  onTokenExpired = 'onTokenExpired',
  onTokenRetrieved = 'onTokenRetrieved',
}

export type Callback<Payload = any> = (payload?: Payload) => void

export interface AxiosServerErrorResponse {
  response: {
    data: ServerErrorResponse
  }
}

export interface ServerErrorResponse {
  errorName: string
  error: string
  status: number
  sentryId?: string
}

export type TaskInput = Record<string, Record<string, string | string[]>> | 'snoozed'
